import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { TEMPLATE_KEYS } from "@Utils";
import { TeamMemberSocialIcons } from "@Modules/hostPage";
import styles from "./teamMemberCard.module.scss";

export const TeamMemberCard = ({
    data,
    cardClick,
}: ITemplate.ITeamMemberCardProps) => {
    const [readMore, setReadMore] = React.useState(false);
    return (
        <div className={styles.root} onClick={() => cardClick(data)}>
            <img className={styles.image} src={data.displayImage} alt="Card" />
            <div className={styles.iconBox}>
                <TeamMemberSocialIcons
                    template={TEMPLATE_KEYS.YOGA}
                    socialInfo={data.socialInfo}
                />
            </div>
            <div className={styles.cardBody}>
                <div className={styles.cardName}>
                    {data.memberName.slice(0, 21)}
                </div>
                <div className={styles.cardtitle}>
                    {data.staffHeading.slice(0, 60)}
                </div>
                <hr className={styles.horizontalBar} />
                <p className={styles.staffDescription}>
                    {readMore
                        ? data.staffDescription
                        : `${data.staffDescription.substring(0, 40)}`}
                    <br />
                </p>
                <div className={styles.readMoreButton}>Show more...</div>
            </div>
        </div>
    );
};
