import { SELECTORS } from "@Redux";
import {
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    getItemCountFromResponsive,
    isEmpty,
} from "@Utils";
import { logError } from "repoV2/utils/common/error/error";
import React from "react";
import MultiCarousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { brandResponsive, CREATOR_MISCELLANEOUS_DATA_TYPES } from "../data";
import { ICreatorMiscellaneousData } from "../ICreatorMiscellaneousData";
import module_style from "../Style.module.scss";

export const BrandsList = ({ titleClass }: { titleClass: string }) => {
    const hostName = useSelector(SELECTORS.hostName)!;

    const [brands, setBrands] = React.useState<
        ICreatorMiscellaneousData.IProps["brand_collaborations"] | undefined
    >(undefined);

    const itemCount = getItemCountFromResponsive(brandResponsive);

    const listData = brands?.data || [];
    const itemExceeded = listData && listData?.length > itemCount;
    const carouselContainerClass = itemExceeded ? "" : "justify-content-center";

    React.useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.BRAND_COLLABORATION,
                    }),
                });
                if (responseData.status === 200) {
                    const brandsData =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.BRAND_COLLABORATION
                        );

                    setBrands({
                        section_name: brandsData?.data?.section_name,
                        data: brandsData?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "BrandsList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    if (isEmpty(listData)) return <></>;

    return (
        <div
            className={`${module_style.conatiner} mb-4 d-flex flex-column align-items-center w-100 ${module_style.templateColor}`}
        >
            <div
                style={{ marginBottom: 40 }}
                className={`${module_style.section_name} ${titleClass}`}
            >
                {brands?.section_name}
            </div>

            <div
                className={`${module_style.galleryOuterDiv} ${module_style.brandWidth}`}
            >
                <MultiCarousel
                    responsive={brandResponsive}
                    ssr
                    containerClass={carouselContainerClass}
                    arrows={false}
                    autoPlay
                    infinite={itemExceeded}
                >
                    {listData.map(item => (
                        <div
                            className={`ml-md-4 mt-4 ml-3 `}
                            key={`brand_${item?.id}`}
                        >
                            <ExlyImage
                                fetchWidth={250}
                                className={`${module_style.brand_logo}`}
                                src={item?.brand_logo}
                                alt={item.brand_name}
                            />
                        </div>
                    ))}
                </MultiCarousel>
            </div>
        </div>
    );
};
