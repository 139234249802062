import React from "react";
import { CustomCodeSection as CustomCodeSectionBase } from "@Modules/customCode/CustomCodeSection";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "../../root/eduverse.module.scss";

export const CustomCodeSection = ({ position }: { position: number }) => (
    <CustomCodeSectionBase
        position={position}
        Title={({ children }) => (
            <div className={classNames(styles.sectionTitle, "pb-5")}>
                {children}
            </div>
        )}
        Section={({ children }) => (
            <section className="px-3 pb-5">{children}</section>
        )}
    />
);
