import React, { useEffect, useState } from "react";
import { IHost } from "@Interfaces";
import { useIsDesktop, useIsTablet } from "repoV2/utils/common/render/screen";
import PortfolioCard from "./PortfolioCard";
import { IPortfolio } from "./portfolio";
import styles from "./portfolio.module.scss";
import { DEFAULT_COLUMNS_COUNT } from "./portfolio.constant";

const dummyData: IPortfolio.IProps["galleryData"] = [
    "400x500",
    "400x400",
    "400x700",
    "400x300",
    "400x300",
]
    .map(
        (dimensions, i) =>
            `https://dummyimage.com/${dimensions}/999/ffffff&text=Sample+Image+${
                i + 1
            }`
    )
    .map((u, i) => ({
        id: i,
        creator: -1,
        type: "image",
        media: u,
        caption: `Portfolio+${i % 2 === 0 ? "Image" : "Video"}+${i}`,
        uuid: `image-${i}`,
        priority: i,
    }));

const Portfolio = ({
    galleryData,
    onGalleryItemClick,
    preview,
}: IPortfolio.IProps): JSX.Element => {
    const [numberOfColumns, setNumberOfColumns] = useState(
        DEFAULT_COLUMNS_COUNT
    );
    const isMobile = !useIsDesktop();
    const isTablet = useIsTablet();

    const dataSource =
        galleryData.length === 0 && preview ? dummyData : galleryData;

    // Creating state for numberOfColumns, as isMobile and isTablet values
    // won't be similar to initially (on server side).
    // This causes ui to break on initial render.
    useEffect(() => {
        const newNumOfClmn = 1 + [isMobile, !isTablet].filter(x => !x).length;
        setNumberOfColumns(newNumOfClmn);
    }, [isMobile, isTablet]);

    // Todo: Simplify this logic
    let i = 0;
    const galleryMatrix: Array<Array<IHost.IGalleryItem>> = [];
    (dataSource || []).forEach(galleryItem => {
        if (!galleryMatrix[i]) {
            galleryMatrix.push([]);
        }
        galleryMatrix[i].push(galleryItem);
        i = (i + 1) % numberOfColumns;
    });

    return (
        <div className={styles.root}>
            {galleryMatrix &&
                galleryMatrix.map((column, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={styles.col}>
                        {column.map(portfolioCardData => {
                            return (
                                <PortfolioCard
                                    data={portfolioCardData}
                                    key={portfolioCardData.uuid}
                                    onClickMediaCard={() => {
                                        onGalleryItemClick(portfolioCardData);
                                    }}
                                />
                            );
                        })}
                    </div>
                ))}
        </div>
    );
};

export default Portfolio;
