import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import style from "./StatsSection.module.scss";

function StatsSection({
    hideComponent,
    title,
    data,
}: ITemplate.IStatsSectionProps) {
    if (hideComponent) return null;
    return (
        <section>
            <div className={style.strengthRoom}>
                <h1>
                    {title === "Company Matrix" ? "Strength in Numbers" : title}
                </h1>
                <div className={style.strengthCard}>
                    {data.map(stat => (
                        <React.Fragment key={stat.id}>
                            <div className={style.strengthContainer}>
                                <div className={style.strengthHead}>
                                    {stat.value}
                                </div>
                                <div className={style.strengthTxt}>
                                    {stat.description}
                                </div>
                            </div>
                            <div className={style.separator} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default StatsSection;
