/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import Portfolio from "@Modules/hostPage/Portfolio";
import { SECTION_IDS } from "@Utils";
import styles from "./portfolio.module.scss";
import commonStyles from "../../../yoga/root/yoga.module.scss";

const EduversePortfolio = ({
    title,
    hideComponent,
    galleryData,
    onGalleryItemClick,
    preview,
}: ITemplate.IGallerySectionProps) => {
    if (hideComponent) return null;
    return (
        <div className={styles.portfolioRoot}>
            <a
                id={SECTION_IDS.gallery}
                className={commonStyles.sectionAnchor}
            />
            <div className={styles.portfolioTitle}>{title}</div>
            <div className={styles.portfolioCardsContainer}>
                <Portfolio
                    galleryData={galleryData}
                    onGalleryItemClick={onGalleryItemClick}
                    preview={preview}
                />
            </div>
        </div>
    );
};

export default EduversePortfolio;
