import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import style from "./TopFeaturesSection.module.scss";

const TopFeaturesSection = ({
    hideGetInTouch,
    getInTouchCtaText,
    onGetInTouchClick,
    hideComponent,
    title,
    description,
    data,
}: ITemplate.ITopFeaturesSectionProps) => {
    if (hideComponent) return null;
    return (
        <div className={style.getInTouchRoot}>
            <div className={style.getInTouchBox}>
                <div className={style.getInTouchBoxOne}>
                    <div className={style.title}>{title}</div>
                    <div className={style.description}>{description}</div>
                    {!hideGetInTouch ? (
                        <ButtonV2
                            applyTheme
                            onClick={() => {
                                onGetInTouchClick();
                            }}
                            className={style.ctaButton}
                        >
                            {getInTouchCtaText}
                        </ButtonV2>
                    ) : null}
                </div>
                <div className={style.getInTouchBoxTwo}>
                    <div className={style.getInTouchBoxTwoSub}>
                        {data.map(item => (
                            <div className={style.getInTouchCard} key={item.id}>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopFeaturesSection;
