import React from "react";
import { VideoPlayer } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./portfolioCard.module.scss";

const PortfolioCard = (props: ITemplate.IPortfolioCard): JSX.Element => {
    const { data, onClickMediaCard } = props;

    return (
        <div className={styles.portfolioCard}>
            <div
                role="presentation"
                className={styles.portfolioImgContainer}
                onClick={onClickMediaCard}
            >
                {(data.type === "video" && (
                    <div className={styles.video}>
                        <VideoPlayer
                            videoUrl={data.media}
                            controls
                            light
                            optimizeUsingThumbnail
                            onClick={onClickMediaCard}
                            height="100%"
                            width="100%"
                        />
                    </div>
                )) ||
                    (data.media && (
                        <div className={styles.image}>
                            <ExlyImage
                                fetchWidth={500}
                                src={data.media}
                                loading="lazy"
                                alt="portfolio"
                            />
                        </div>
                    )) || <img src="" alt="" />}
            </div>
        </div>
    );
};

export default PortfolioCard;
