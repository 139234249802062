/* eslint-disable jsx-a11y/anchor-has-content */
import { CarouselCustomDot } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS } from "@Utils";
import React from "react";
import Carousel from "react-multi-carousel";
import { TeamMemberCard } from "./TeamMemberCard";
import styles from "./teamSection.module.scss";
import commonStyles from "../../../yoga/root/yoga.module.scss";

const responsive = {
    largest: {
        breakpoint: { max: 4000, min: 1351 },
        items: 4,
    },
    large: {
        breakpoint: { max: 1350, min: 1001 },
        items: 3,
    },
    medium: {
        breakpoint: { max: 1000, min: 681 },
        items: 2,
    },
    small: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};

export const TeamSection = ({
    hideComponent,
    title,
    teamMembersData,
    cardClick,
}: ITemplate.ITeamSectionProps) => {
    if (hideComponent) return null;

    return (
        <div className={styles.root}>
            <a id={SECTION_IDS.team} className={commonStyles.sectionAnchor} />
            <div>
                <div className={styles.title}>{title}</div>
                <Carousel
                    responsive={responsive}
                    infinite
                    arrows={false}
                    showDots
                    renderDotsOutside
                    dotListClass={styles.dotListClass}
                    className={styles.carouselContainer}
                    customDot={
                        <CarouselCustomDot
                            design="dot"
                            className={styles.customDot}
                            applyTheme
                        />
                    }
                >
                    {teamMembersData.map(item => (
                        <TeamMemberCard
                            key={item.username}
                            data={item}
                            cardClick={cardClick}
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
