import {
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    getItemCountFromResponsive,
    isEmpty,
    MEDIA_MENTION_PLACEHOLDER_IMAGE,
    SELECTORS,
} from "@Utils";
import React from "react";
import MultiCarousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { MdSchedule } from "@react-icons/all-files/md/MdSchedule";
import { logError } from "repoV2/utils/common/error/error";
import { CarouselCustomDot } from "@Modules/common";
import { ICarouselCustomDotProps } from "repoV2/components/common/Carousel/CarouselCustomDot/CarouselCustomDot.interfaces";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { ICreatorMiscellaneousData } from "../ICreatorMiscellaneousData";
import module_style from "../Style.module.scss";
import { CREATOR_MISCELLANEOUS_DATA_TYPES, mediaResponsive } from "../data";
import useCreatorMiscellaneousData from "../useCreatorMiscellaneousData";

export const MediaList = ({
    titleClass,
    conatinerClass,
    dotDesign,
}: {
    titleClass: string;
    conatinerClass?: string;
    dotDesign?: ICarouselCustomDotProps["design"];
}) => {
    const { onMediaItemClick } = useCreatorMiscellaneousData();
    const hostName = useSelector(SELECTORS.hostName)!;

    const [media, setMedia] = React.useState<
        ICreatorMiscellaneousData.IProps["media_mentions"] | undefined
    >(undefined);

    const itemCount = getItemCountFromResponsive(mediaResponsive);

    const listData = media?.data || [];
    const itemExceeded = listData && listData?.length > itemCount;
    const carouselContainerClass = itemExceeded ? "" : "justify-content-center";

    React.useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.MEDIA_MENTIONS,
                    }),
                });
                if (responseData.status === 200) {
                    const mediaData =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.MEDIA_MENTIONS
                        );

                    setMedia({
                        section_name: mediaData?.data?.section_name,
                        data: mediaData?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "MediaList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    if (isEmpty(listData)) return <></>;

    return (
        <div
            className={`${module_style.conatiner} mb-4 d-flex flex-column align-items-center w-100 ${module_style.templateColor} ${conatinerClass}`}
        >
            <div className={`${module_style.section_name} ${titleClass}`}>
                {media?.section_name}
            </div>

            <div
                className={`${module_style.galleryOuterDiv} ${module_style.mediaWidth}`}
            >
                <MultiCarousel
                    responsive={mediaResponsive}
                    ssr
                    containerClass={carouselContainerClass}
                    arrows={false}
                    autoPlay
                    infinite={itemExceeded}
                    showDots
                    renderDotsOutside
                    dotListClass={module_style.dotListClass}
                    customDot={
                        <CarouselCustomDot
                            design={dotDesign}
                            className={module_style.customDot}
                            applyTheme
                        />
                    }
                >
                    {listData.map(item => (
                        <div
                            key={`media_${item?.id}`}
                            onClick={() => onMediaItemClick(item)}
                            className={`m-auto ${module_style.media_card}`}
                        >
                            <ExlyImage
                                fetchWidth={500}
                                src={
                                    item.cover_image
                                        ? item.cover_image
                                        : MEDIA_MENTION_PLACEHOLDER_IMAGE
                                }
                                alt={item.media_title}
                                className={`${module_style.cover_image} ${
                                    item.cover_image
                                        ? module_style.linear_gradient
                                        : ""
                                }`}
                            />
                            <div className={`${module_style.text_container}`}>
                                <div className={`d-flex align-items-center `}>
                                    <ExlyImage
                                        fetchWidth={52}
                                        className={`${module_style.media_logo} mr-2`}
                                        src={item?.media_logo}
                                        alt="media_logo"
                                    />
                                    <div
                                        className={` ${module_style.media_organistaion_name}`}
                                    >
                                        {item.organisation_name}
                                    </div>
                                </div>
                                <div
                                    className={`mt-2 ${module_style.award_title} ${module_style.max_2_line}`}
                                >
                                    {item.media_title}
                                </div>
                                <div
                                    className={`mt-1 ${module_style.info} d-flex align-items-center`}
                                >
                                    <MdSchedule
                                        className={`${module_style.schedule_svg}`}
                                    />
                                    <span className="ml-1">{item.date}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </MultiCarousel>
            </div>
        </div>
    );
};

MediaList.defaultProps = {
    dotDesign: "dot",
    conatinerClass: "",
};
