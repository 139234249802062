import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { colorByListingType, MEDIA, trimText } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./eventListing.module.scss";

export const EventListing = ({
    data,
    onEventListingClick,
    showSkuLabelAsTag = false,
}: {
    data: ITemplate.IEventListingData;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
    showSkuLabelAsTag?: boolean;
}) => {
    const metadata = data?.metadata;
    const cardLabelContent = metadata?.card_label?.content;
    const cardLabelColor = metadata?.card_label?.color || "white";
    const cardLabelBgColor = metadata?.card_label?.background || "black";
    return (
        <div
            className={classNames(styles.container)}
            onClick={() => onEventListingClick(data)}
        >
            <div className={styles.imageContainer}>
                <ExlyImage
                    alt={data.title}
                    className={classNames(styles.image)}
                    src={data.cover_image || MEDIA.DEFAULT.CARD_IMAGE}
                    fetchWidth={500}
                    height={167.25}
                />

                <div className={styles.labelsContainer}>
                    {showSkuLabelAsTag ? (
                        <div
                            className={classNames(styles.label)}
                            style={{
                                backgroundColor: colorByListingType(
                                    data.type,
                                    "isPlan" in data && data.isPlan
                                ),
                            }}
                        >
                            {("sku_title" in data && data.sku_title) ||
                                ("listingTypeTitle" in data &&
                                    data.listingTypeTitle) ||
                                null}
                        </div>
                    ) : null}
                    {cardLabelContent ? (
                        <div
                            className={classNames(styles.label)}
                            style={{
                                color: cardLabelColor,
                                backgroundColor: cardLabelBgColor,
                            }}
                        >
                            {cardLabelContent}
                        </div>
                    ) : null}
                </div>
            </div>

            <div className={styles.detailsContainer}>
                {data.hidePrice ? null : (
                    <div className={styles.amount}>
                        <div className={styles.price}>
                            <div>
                                {data?.currency}
                                {Math.round(data.updated_price)}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        {trimText(data.title, 80)}
                    </div>
                    {!metadata?.hide_schedule ? (
                        <div
                            className={styles.shortDescription}
                            // TODO: remove trimText from dangerouslySetInnerHTML as its not a good approach to trim HTML string directly use ReadMoreWrapper
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: trimText(data.short_description, 100),
                            }}
                        />
                    ) : null}
                    <hr className={styles.divider} />
                    <ButtonV2 applyTheme className={styles.ctaButton}>
                        {data.ctaText}
                    </ButtonV2>
                </div>
            </div>
        </div>
    );
};

EventListing.defaultProps = {
    showSkuLabelAsTag: false,
};
