/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import { SECTION_IDS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "./eventSectionTitle.module.scss";
import commonStyles from "../../../../../yoga/root/yoga.module.scss";

export const EventSectionTitle = ({
    eventSectionTitle,
    index,
}: {
    eventSectionTitle: string;
    index?: number;
}) => {
    return (
        <>
            {isDefined(index) ? (
                <a
                    id={SECTION_IDS.eventSection(index!)}
                    className={commonStyles.sectionAnchor}
                />
            ) : null}
            <div className={classNames(styles.sectionTitle)}>
                {eventSectionTitle}
            </div>
        </>
    );
};

EventSectionTitle.defaultProps = {
    index: undefined,
};
