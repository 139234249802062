import React from "react";
import { Navbar } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { AwardsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/AwardsList";
import { BrandsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/BrandsList";
import { MediaList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/MediaList";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import style from "./eduverse.module.scss";
import AboutUs from "../components/AboutUs/index";
import TopFeaturesSection from "../components/TopFeaturesSection";
import StatsSection from "../components/StatsSection";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { TeamSection } from "../components/TeamSection";
import { BlogPostsSection } from "../components/BlogPostsSection";
import { EventSections } from "../components/EventSections";
import Portfolio from "../components/Portfolio";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const Eduverse = ({
    navbarProps,
    footerSectionProps,
    aboutSectionProps,
    eventSectionsProps,
    teamSectionProps,
    blogPostsSectionProps,
    testimonialsSectionProps,
    gallerySectionProps,
    topFeaturesProps,
    statsSectionProps,
}: ITemplate.IProps): JSX.Element => {
    return (
        <div className={style.root}>
            <Navbar {...navbarProps} className={style.navbar} />
            <AboutUs {...aboutSectionProps} />
            <CustomCodeSection
                position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
            />
            <AwardsList titleClass={`${style.sectionTitle}`} />
            <EventSections {...eventSectionsProps} />
            <TopFeaturesSection {...topFeaturesProps} />
            <BrandsList titleClass={`${style.sectionTitle}`} />
            <StatsSection {...statsSectionProps} />
            <TestimonialsSection {...testimonialsSectionProps} />
            <TeamSection {...teamSectionProps} />
            <MediaList titleClass={`${style.sectionTitle}`} />
            <Portfolio {...gallerySectionProps} />
            <BlogPostsSection {...blogPostsSectionProps} />
            <CustomCodeSection
                position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
            />
            <FooterSection {...footerSectionProps} />
        </div>
    );
};

export default Eduverse;
