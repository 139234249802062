// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { isEmpty } from "@Utils";
import { ICreatorMiscellaneousData } from "./ICreatorMiscellaneousData";

const useCreatorMiscellaneousData = () => {
    const onMediaItemClick = (
        item: ICreatorMiscellaneousData.IProps["media_mentions"]["data"][0]
    ) => {
        if (isEmpty(item.link)) return;
        window.open(item.link);
    };

    return {
        onMediaItemClick,
    };
};

export default useCreatorMiscellaneousData;
