/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Carousel from "react-multi-carousel";
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS, DotMatrixIconSvg } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";

import { TestimonialCard } from "./TestimonialCard";
import styles from "./testimonialsSection.module.scss";
import commonStyles from "../../../yoga/root/yoga.module.scss";

export const TestimonialsSection = ({
    hideComponent,
    title,
    testimonialsData,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
}: ITemplate.ITestimonialsSectionProps) => {
    const isMobile: boolean = useMediaQuery("(max-width: 768px)");
    const isTablet: boolean = useMediaQuery("(max-width: 1024px)");

    const responsive = {
        all: {
            breakpoint: { max: 4000, min: 0 },
            items: 1 + [isMobile, isTablet].filter(x => !x).length,
        },
    };

    if (hideComponent) return null;

    return (
        <div className={styles.root}>
            <a
                id={SECTION_IDS.testimonials}
                className={commonStyles.sectionAnchor}
            />
            <div
                className={classNames(
                    styles.container,
                    testimonialsData.length < 3 &&
                        styles.singleTestimonialContainer
                )}
            >
                <div className={classNames(styles.title)}>{title}</div>
                <DotMatrixIconSvg className={styles.dotMatrix} />
                <Carousel
                    responsive={responsive}
                    infinite
                    autoPlay={
                        testimonialsData.length > 1 &&
                        !pauseTestimonialsAutoplay
                    }
                    arrows={false}
                    showDots
                    renderDotsOutside
                    dotListClass={styles.dotListClass}
                >
                    {testimonialsData?.map(item => (
                        <TestimonialCard
                            key={item.id}
                            data={item}
                            onTestimonialClick={e =>
                                onTestimonialClick(e, item)
                            }
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
