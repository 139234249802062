import React from "react";
import {
    getMaxLengthBasedOnDeviceWidth,
    ImagePreviewIconSvg,
    QuoteBeginIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { ITemplate } from "@Templates/ITemplate";

import { ButtonV2 } from "@Modules/common/ButtonV2";
import { VideoPlayer } from "@Modules/common";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./testimonialCard.module.scss";

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps) => {
    const TestimonialContent = () => {
        if (data?.type === TESTIMONIAL_DATA_TYPE.VIDEO) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.videoContainer
                    )}
                    onClick={onTestimonialClick}
                >
                    <VideoPlayer
                        light
                        optimizeUsingThumbnail
                        videoUrl={data.media}
                        width="100%"
                        height="100%"
                    />
                    <div className={styles.mediaOverlay} />
                </div>
            );
        }

        if (data?.type === TESTIMONIAL_DATA_TYPE.IMAGE) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.imageContainer
                    )}
                    onClick={onTestimonialClick}
                >
                    <ExlyImage
                        fetchWidth={500}
                        alt={data.name}
                        src={data.media}
                        className={styles.image}
                    />

                    <div className={styles.mediaOverlay}>
                        <ImagePreviewIconSvg height="40px" />
                    </div>
                </div>
            );
        }

        const maxLength = getMaxLengthBasedOnDeviceWidth([
            [780, 280],
            [420, 200],
            [360, 160],
        ]);

        const showReadMore: boolean = !!(data?.content?.length > maxLength);

        return (
            <div className={styles.contentContainer}>
                {data.content.substring(0, maxLength)}
                {showReadMore ? (
                    <>
                        ...&nbsp;
                        <ButtonV2
                            design="plain"
                            applyTheme
                            className={styles.readMore}
                            onClick={onTestimonialClick}
                        >
                            Read More
                        </ButtonV2>
                    </>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div className={classNames(styles.container)}>
                <div className={styles.quoteContainer}>
                    <QuoteBeginIconSvg className={styles.quote} />
                </div>
                <TestimonialContent />
                <hr className={styles.testimonialHr} />
                <div className={styles.authorContainer}>
                    <ExlyImage
                        fetchWidth={120}
                        alt={data.name}
                        src={data.cover_image}
                        width={60}
                        height={60}
                    />
                    <div className={styles.authorName}>
                        {trimText(data.name, 30)}
                    </div>
                </div>
            </div>
        </div>
    );
};
